import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Drawer, Timeline } from 'antd'
import _ from 'lodash'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { LOGIN } from './graphql/Mutation'
import { GET_USER_MENUS, GET_SESSION_EXPIRE, GET_SOFTWARE_VERSION, GET_CONFIG } from './graphql/Query'
import { Spinners, SpinnersSmall, Helpers, Alert } from 'pda-components'
import dayjs from 'dayjs'
import iconsMa from '../../assets/images/icons-ma.png'

const Login = () => {
  const { t } = useTranslation()
  const { Actions, displayError, useAuth, getEnv, encryptData, convertDate2FullDate } = Helpers
  const [, , updateData] = useAuth()
  const navigate = useNavigate()
  const [version, setVersion] = useState([])
  const { data: configData, error: configError } = useQuery(GET_CONFIG, {
    variables: {
      ...Actions('view')
    }
  })

  const [getVersionFn, { data: versionData, loading: versionLoading, error: versionError }] = useLazyQuery(
    GET_SOFTWARE_VERSION,
    {
      variables: {
        ...Actions('view')
      }
    }
  )

  const [login, { data: loginData, loading: loginLoading, error: loginError }] = useMutation(LOGIN)
  const [getMenus, { data: menusData, loading: menusLoading, error: menusError }] = useLazyQuery(GET_USER_MENUS)
  const [getSessionExpire, { data: sessionExpireData, loading: sessionExpireLoading, error: sessionExpireError }] =
    useLazyQuery(GET_SESSION_EXPIRE)

  const [openDrawer, setOpenDrawer] = useState(false)
  const showDrawer = () => {
    getVersionFn({
      variables: {
        ...Actions('view')
      }
    })

    setOpenDrawer(true)
  }
  const onClose = () => {
    setOpenDrawer(false)
  }

  const onFinish = async (values) => {
    if (values?.username && values?.password) {
      await login({
        variables: {
          ...Actions('login'),
          input: {
            username: values?.username,
            password: values?.password
          }
        }
      })
    }
  }

  useEffect(() => {
    if (localStorage.getItem('user')) {
      navigate('/authen')
    }
  }, [])

  useEffect(() => {
    if (loginData?.login) {
      const data = loginData?.login
      const ciphertext = encryptData(data)
      localStorage.setItem('user', ciphertext)
      getMenus({
        variables: {
          ...Actions('view')
        }
      })
      getSessionExpire({
        variables: {
          ...Actions('view')
        }
      })
    }
  }, [loginData])

  useEffect(() => {
    if (loginError) {
      Alert({
        type: 'error',
        title: t(displayError(loginError)),
        onOk() {}
      })
    }
  }, [loginError])

  useEffect(() => {
    if (configError) {
      Alert({
        type: 'error',
        title: t(displayError(configError)),
        onOk() {}
      })
    }
  }, [configError])

  useEffect(() => {
    if (versionError) {
      Alert({
        type: 'error',
        title: t(displayError(versionError)),
        onOk() {}
      })
    }
  }, [versionError])

  useEffect(() => {
    if (menusData && sessionExpireData) {
      //--- sessionExpire ---
      const sessionExpireMin = sessionExpireData?.getSessionExpire?.sessionExpire || 0
      if (sessionExpireMin > 0) {
        localStorage.setItem('sess_expire_min', encryptData(sessionExpireMin))
        localStorage.setItem('sess_start_time', encryptData(dayjs().format('YYYY-MM-DD HH:mm:ss')))

        //--- menu ---
        const menus = menusData.layoutMenus ? menusData.layoutMenus : []
        localStorage.setItem('menus', JSON.stringify(menus))
        updateData({}) //set login global

        if (localStorage.getItem('session_expired_url')) {
          window.location.href = localStorage.getItem('session_expired_url')
        } else {
          navigate('/authen')
        }
      } else {
        Alert({
          type: 'warning',
          title: t('แจ้งเตือน'),
          content: t('กรุณาไปตั้งค่า Session Expire (นาที) ก่อน'),
          onOk() {}
        })
      }
    }
  }, [menusData, sessionExpireData])

  useEffect(() => {
    if (sessionExpireError) {
      console.log(sessionExpireError)
    }
  }, [sessionExpireError])

  useEffect(() => {
    if (menusError) {
      console.log(menusError)
    }
  }, [menusError])

  useEffect(() => {
    if (versionData) {
      const versionList = _.reverse(versionData?.getSoftwareVersion)
      setVersion(versionList)
    }
  }, [versionData])

  return (
    <>
      {(sessionExpireLoading || menusLoading) && <SpinnersSmall />}
      <Row justify="center">
        {configData?.getConfig?.version ? (
          configData?.getConfig?.closeweb ? (
            <Col
              {...{
                xs: 24, //screen < 576px
                md: 16, //screen ≥ 768px
                xl: 12, //screen ≥ 1200px
                xxl: 10 //screen ≥ 1600px
              }}
            >
              <div className="content content-closeweb">
                <h2 className="c-title">
                  <img src={iconsMa} /> ขอแจ้งปรับปรุงระบบ
                </h2>

                {dayjs(configData?.getConfig?.closeweb_startdate).format('YYYY-MM-DD') ===
                dayjs(configData?.getConfig?.closeweb_enddate).format('YYYY-MM-DD') ? (
                  <>
                    <p>
                      {'วันที่ ' + convertDate2FullDate(configData?.getConfig?.closeweb_startdate)}
                      {' เวลา ' +
                        dayjs(configData?.getConfig?.closeweb_startdate).format('HH:mm') +
                        ' - ' +
                        dayjs(configData?.getConfig?.closeweb_enddate).format('HH:mm') +
                        ' น.'}
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      {'วันที่ ' + convertDate2FullDate(configData?.getConfig?.closeweb_startdate)}
                      {' เวลา ' + dayjs(configData?.getConfig?.closeweb_startdate).format('HH:mm') + ' น.'}
                    </p>
                    <p>
                      {'ถึง วันที่ ' + convertDate2FullDate(configData?.getConfig?.closeweb_enddate)}
                      {' เวลา ' + dayjs(configData?.getConfig?.closeweb_enddate).format('HH:mm') + ' น.'}
                    </p>
                  </>
                )}

                <p>ขออภัยในความไม่สะดวก</p>
              </div>
            </Col>
          ) : (
            <Col
              {...{
                xs: 24, //screen < 576px
                md: 11, //screen ≥ 768px
                xl: 8, //screen ≥ 1200px
                xxl: 6 //screen ≥ 1600px
              }}
            >
              <div className="content login">
                <div className="title-header">{t('พิงคนคร ยินดีต้อนรับ')}</div>
                <div className="title-second">
                  {t('กรุณาล็อกอิน เพื่อเข้าสู่ระบบ')}

                  {!['prod'].includes(getEnv('REACT_APP_ENV')) ? (
                    <span className="text-error">{' (' + getEnv('REACT_APP_ENV')?.toUpperCase() + ')'}</span>
                  ) : null}
                </div>

                <Row justify="center">
                  <Col
                    {...{
                      xs: 22,
                      md: 22,
                      lg: 22
                    }}
                  >
                    <Form
                      name="form-login"
                      layout="vertical"
                      labelCol={{
                        span: 24
                      }}
                      wrapperCol={{
                        span: 24
                      }}
                      onFinish={onFinish}
                      autoComplete="off"
                    >
                      <Form.Item
                        label={t('ชื่อผู้ใช้งาน')}
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: t('กรุณาระบุชื่อผู้ใช้งาน')
                          }
                        ]}
                      >
                        <Input placeholder={t('Username')} />
                      </Form.Item>
                      <Form.Item
                        label={t('รหัสผ่าน')}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t('กรุณาระบุรหัสผ่าน')
                          }
                        ]}
                      >
                        <Input.Password placeholder={t('Password')} className="input-password" />
                      </Form.Item>
                      <div className="text-center">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-login"
                          loading={loginLoading ? true : false}
                        >
                          {t('เข้าสู่ระบบ')}
                        </Button>
                        <div className="forgotpassword">
                          <Link to="/forgotpassword">
                            <u>{t('ลืมรหัสผ่าน')}</u> ?
                          </Link>
                        </div>
                        {configData?.getConfig?.version && (
                          <div className="versions">
                            <a className="version" onClick={showDrawer}>
                              {t('เวอร์ชัน')} {configData?.getConfig?.version}
                            </a>
                          </div>
                        )}
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          )
        ) : null}
      </Row>

      <Drawer
        title={t('ประวัติการอัปเดตซอฟแวร์')}
        onClose={onClose}
        open={openDrawer}
        width={window.innerWidth > 767 ? 500 : '100%'}
        className="drawer-versions"
      >
        {versionLoading && <Spinners />}
        {version.map((item, key) => {
          return (
            <Timeline.Item key={key} className="timeline-versions">
              <div key={key}>
                <h4 style={{ fontWeight: '500' }}>
                  v{item.version} ({item.date})
                </h4>
                <ul className="versions">
                  {item?.detail.map((item2, key2) => {
                    return <li key={key2}>{item2}</li>
                  })}
                </ul>
              </div>
            </Timeline.Item>
          )
        })}
      </Drawer>
    </>
  )
}

export default Login
