import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider, ApolloProvider } from 'pda-components'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import thTH from 'antd/lib/locale/th_TH'
import enUS from 'antd/lib/locale/en_US'
import './configs/i18next.config'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { Helpers } from 'pda-components'

fetch('./env.txt')
  .then((response) => response.text())
  .then((config) => {
    const { isEmpty, encryptBase64, decryptBase64, isJsonString } = Helpers
    const envNew = isJsonString(decryptBase64(config))

    // ตรวจสอบว่า deData เป็นอ็อบเจ็กต์ JSON
    if (!isEmpty(envNew) && typeof envNew === 'object') {
      Object.keys(envNew).forEach((key) => {
        // เข้ารหัสค่าเป็น Base64
        envNew[key] = encryptBase64(envNew[key])
      })
    }
    window.env = envNew

    ReactDOM.createRoot(document.getElementById('root')).render(
      <BrowserRouter>
        <AuthProvider>
          <ApolloProvider>
            <ConfigProvider locale={localStorage.getItem('lang') === 'en' ? enUS : thTH}>
              <App />
            </ConfigProvider>
          </ApolloProvider>
        </AuthProvider>
      </BrowserRouter>
    )

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
  })
