import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GET_SYSTEMS } from './graphql/Query'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Spinners, Alert, Helpers, Avatar } from 'pda-components'
import ImgUserDefault from '../../assets/images/default-profile.png'
import { LOGOUT } from './graphql/Mutation'

const Modules = () => {
  const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'th'
  const { Actions, displayError, useAuth } = Helpers

  const [getSystems, { data: dataSystems, loading: loadingSystems }] = useLazyQuery(GET_SYSTEMS)
  const [logOutFnc, { data: logOutData, loading: logOutLoading, error: logOutError }] = useMutation(LOGOUT)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [systemsState, setSystemsState] = useState([])
  const [user, logout] = useAuth()

  const redirectTo = (systemPath = null, systemId = '', systemName) => {
    if (systemPath) {
      window.location.href = systemPath
    } else {
      navigate('main', { state: { systemId: systemId, systemName: systemName } })
    }
  }

  useEffect(() => {
    if (dataSystems) setSystemsState(dataSystems.layoutSystems)
  }, [dataSystems])

  useEffect(() => {
    getSystems({ variables: { action: { action: 'view' } } })
  }, [])

  const onLogout = async () => {
    if (user) {
      await logOutFnc({
        variables: {
          input: {
            userId: user?.userId,
            loginId: user?.loginId
          },
          ...Actions('logout')
        }
      })
    }
  }

  useEffect(() => {
    if (logOutData) {
      logout()
    }
  }, [logOutData])

  useEffect(() => {
    if (logOutError) {
      const msg = displayError(logOutError)

      Alert({
        type: 'error',
        title: t('ไม่สามารถออกจากระบบได้'),
        content: t(msg),
        onOk() {}
      })
    }
  }, [logOutError])

  useEffect(() => {
    if (!localStorage.getItem('user')) {
      Alert({
        type: 'warning',
        title: t('โปรดลงชื่อเข้าใช้งานระบบ'),
        onOk() {
          logout()
        }
      })
    }
  }, [])

  return (
    <>
      {(loadingSystems || logOutLoading) && <Spinners />}
      <div className="contents">
        <Row justify="center">
          <Col
            {...{
              xs: 24, //screen < 576px
              md: 20, //screen ≥ 768px
              xl: 17, //screen ≥ 1200px
              xxl: 13 //screen ≥ 1600px
            }}
          >
            <div className="content module">
              <div className="title-header">{t('เลือกระบบเพื่อเข้าใช้งาน')}</div>
              <div className="title-second">
                <span>
                  <Avatar size={70} img={user?.profilePictureId ? user?.profilePictureId : ImgUserDefault}></Avatar>
                  <div className="text-user">
                    <div className="text-user-row" title={user?.firstname || ''}>
                      {user?.firstname ? `${user?.firstname} ${user?.lastname || ''}` : 'invalid name'}
                    </div>
                    <div className="text-user-row" title={user?.positionName || ''}>
                      {user?.positionName ? user?.positionName : lang === 'en' ? 'Personal User' : 'ผู้ใช้งานระบบ'} | ID
                      :{' '}
                      {user?.employeeCode
                        ? user?.employeeCode
                        : lang === 'en'
                        ? 'No Employee code'
                        : 'ยังไม่มีรหัสพนักงาน'}
                    </div>
                  </div>
                </span>
              </div>
              <Row justify="center">
                {systemsState?.map((item, key) => {
                  return (
                    <Col
                      key={key}
                      {...{
                        xs: 24,
                        md: 8,
                        lg: 8
                      }}
                    >
                      <div
                        className="btn-system"
                        onClick={() => redirectTo(item.systemPath, item.systemId, item.systemNameTH)}
                      >
                        <div className="btn-system-content">
                          <div className="btn-system-bg">
                            {item.systemImg && <img src={item.systemImg} width="95%" />}
                            {/* <div className="logo-system-hrm"></div> */}
                          </div>
                        </div>
                        <div className="btn-system-text">{t(item.systemNameTH ? item.systemNameTH : '')}</div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
              <div className="btn-logout-content">
                <Button type="primary" htmlType="submit" className="btn-login" onClick={() => onLogout()}>
                  {t('ออกจากระบบ')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Modules
