import styled, { createGlobalStyle } from 'styled-components'
import bgLogin2 from '../../assets/images/bg-login3.jpg'
import logoPda from '../../assets/images/logo_pda.svg'
import logoSystemHrm from '../../assets/images/logo-system-hrm.svg'
import logoSystemEsarabun from '../../assets/images/logo-system-esarabun.svg'
import logoSystemAdmin from '../../assets/images/logo-system-admin.svg'

export const LoginLayoutGlobalStyled = createGlobalStyle`
  body {
    overflow: hidden;

    .modal-version {
      .ant-modal-title, .ant-modal-body {
        font-family: Sarabun;
      }
    }

    .drawer-versions {
      font-family: Sarabun;

      .ant-timeline-item-head {
        color: #bf4c50;
        border-color: #bf4c50;
      }

      .versions {
        list-style-type: circle;
        padding-left: 20px;
      }
    }
    
  }
`

export const LoginLayoutStyled = styled.div`
  overflow: auto;
  font-family: 'Kanit';
  background-image: url(${bgLogin2});
  background-color: #ccc;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .content-top {
    position: absolute;
    top: 1rem;
    width: 100%;
    .logo {
      background-image: url(${logoPda});
      width: 238px;
      height: 130px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      //xs = screen < 576px
      @media only screen and (max-width: 576px) {
        width: 200px;
        height: 110px;
      }
    }
    .lang {
      text-align: right;
      color: #fff;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      //xs = screen < 576px
      @media only screen and (max-width: 576px) {
        align-items: flex-start;
      }

      height: 100%;
      font-size: 16px;
      .link-lang {
        color: #fff;
        /* text-shadow: 1px 1px 0px #333;
        &:hover {
          color: #ddd;
        } */
      }
      .line {
        padding: 0px 8px 0px 6px;
      }
    }
  }

  .contents {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .ant-row-center {
      width: 100%;
    }

    .content {
      min-height: 300px;
      padding: 35px 30px 30px;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 4px;

      &.login {
        @media screen and (max-width: 576px) {
          margin-top: 60px;
        }
      }

      &.module {
        @media screen and (max-width: 576px) {
          margin-top: 600px;
          margin-bottom: 50px;
        }

        //sm >= 576px
        @media only screen and (min-width: 576px) {
          width: calc(100% - 20px);
          margin: 0 auto;
        }
      }

      .title-header {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        color: #825041;
      }
      .title-second {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #12263f;
        padding: 10px 0 10px;
      }

      .text-user {
        padding: 5px 0 0;
        line-height: 1.3;
        .text-user-row {
          font-size: 16px;
        }
      }

      #form-login {
        margin-top: 20px;

        .ant-form-item {
          margin-bottom: 20px;

          .ant-input:not(.input-password .ant-input),
          .ant-input-password {
            height: 40px;
            font-size: 14px;
            background: #ffffff;
            border: 1px solid #95aac9;
            border-radius: 2px;
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }

        .ant-form-item-label {
          padding: 0 0 2px;
          .ant-form-item-required::before {
            display: none;
          }
          label {
            font-size: 16px;
          }
        }
      }
    }

    .content-closeweb {
      .c-title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 30px;
      }
      p {
        font-size: 16px;
      }
    }
  }

  .btn-login {
    background: linear-gradient(180deg, #e16721 0%, #ad4549 100%);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    border-radius: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: unset;
    width: 100%;
    @media only screen and (min-width: 576px) {
      width: 260px;
    }
    height: 48px;
    margin: 10px 0px 0px;
    &:hover {
      background: linear-gradient(180deg, #ef7634 0%, #bf4c50 100%);
    }
  }

  .forgotpassword,
  .backtologin {
    margin: 10px 0px 10px;
    a {
      color: #3b507b;
      &:hover {
        color: #ef7634;
      }
    }
  }

  .versions {
    display: block;
    margin-top: 25px;
    margin-bottom: -10px;
    text-align: center;
    font-size: 13px;
    .version {
      color: rgba(0, 0, 0, 0.85);
      &:hover {
        color: #ef7634;
      }
    }
  }

  .btn-system {
    .btn-system-content {
      margin: 10px;
      background: linear-gradient(180deg, #e16721 0%, #ad4549 100%);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 5px;
      .btn-system-bg {
        background: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        @media only screen and (min-width: 576px) {
          height: 170px;
        }
      }
    }
    .btn-system-text {
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #12263f;
      font-weight: bold;
      margin: 0 10px;
    }

    .logo-system-hrm {
      background-image: url(${logoSystemHrm});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .logo-system-esarabun {
      background-image: url(${logoSystemEsarabun});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .logo-system-admin {
      background-image: url(${logoSystemAdmin});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .logo-system-hrm,
    .logo-system-esarabun,
    .logo-system-admin {
      width: 234px;
      height: 137px;
    }

    &:hover {
      cursor: pointer;
      .btn-system-bg {
        background: linear-gradient(180deg, #fffce5 0%, #f7b54c 100%);
      }
      .btn-system-text {
        background: linear-gradient(180deg, #e06721 0%, #ad4549 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .btn-logout-content {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
  }
`
